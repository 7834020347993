<!-- @format -->
<script lang="ts">
  import { type StoresKey, getStoresContext } from "~/stores";

  export let fragment: Fragment;
  export let request_store_key: StoresKey;

  const stores_context = getStoresContext(request_store_key);
  import { valueGetters } from "~/components/field-utils";
  import { onMount } from "svelte";
  const { locale } = stores_context;
  const { getText, getImage } = valueGetters(stores_context);

  $: title = $locale && getText("page-title", fragment);
  $: desktop = $locale && getImage("banner-image-desktop", fragment);
  $: mobile = $locale && getImage("banner-image-mobile", fragment);

  let header: HTMLElement | null;
  onMount(async () => {
    header = document.querySelector("header");
  });

  $: if ($locale && !desktop && header) {
    header.style.backgroundColor = "var(--color-brand-primary)";
  }
</script>

{#if $locale}
  <div class="subpage-banner" class:height-254={!desktop}>
    <div class="overlay-1" class:d-none={!desktop}></div>
    <div class="overlay-2" class:d-none={!desktop}></div>

    {#if desktop && "src" in desktop}
      <picture>
        <source srcset={desktop.src} media="(min-width: 992px)" />
        <img
          alt={desktop?.alt}
          src={mobile && "src" in mobile ? mobile?.src : desktop.src} />
      </picture>
    {/if}
    <h1>{@html title}</h1>
  </div>
{/if}

<style lang="scss">
  .overlay-1 {
    width: 100%;
    height: 50%;
    background: transparent linear-gradient(180deg, #4f4f4f 0%, #4f4f4f00 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    position: absolute;
  }
  .overlay-2 {
    width: 100%;
    height: 100%;
    background: #4f4f4f 0% 0% no-repeat padding-box;
    opacity: 0.2;
    position: absolute;
  }
  .subpage-banner {
    position: relative;
    margin-bottom: 48px;
    color: var(--color-accent-white);

    h1 {
      position: absolute;
      bottom: 56px;
      text-align: center;
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 40px;
      font-family: var(--font-bold);
      margin: 0 auto;
    }
  }
  picture,
  img {
    width: 100%;
    aspect-ratio: 375/540;
    object-fit: cover;

    @media screen and (min-width: 992px) {
      aspect-ratio: 1280/417;
    }
  }
  .height-254 {
    height: 222px;
    color: var(--color-accent-black);
    h1 {
      bottom: 0;
    }
  }
</style>
